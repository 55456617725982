<template>
    <div v-if="src" style="height: 100%;overflow: hidden;" v-loading="loading">
        <iframe :src="src" allowfullscreen="true" scrolling="auto" frameborder="0" style="width:100%;height:100%;" @load="onLoaded"></iframe>
    </div>
</template>
<script>
    import Vue from 'vue';
    import { CLOUD_LOGISTIC_API } from '@/config';
    import { logOut } from "@/service/account";
    export default {
        data() {
            return {
                uri:CLOUD_LOGISTIC_API,
                src:null,
                loading:true,
                comp_type: null, // 1工厂，2设计公司，3销售公司，4共享打样，5供应商，7物流公司，8金融服务公司，9风控公司
            }
        },
        beforeRouteLeave(to, from, next) {
            this.src=null;
            next();
        },
        created() {
            this.comp_type = this.$store.state.loginUser.comp.comp_type;
            console.log(this.$route.query,this.uri)
            this.src=this.uri;
            if(this.$route.query.path && this.$route.query.path.length>0){
                this.src+=this.$route.query.path
            }
            // else{
            //     this.src+="/center";
            // }
            if(this.src.indexOf('?')>=0){
                this.src+=("&token="+this.$store.state.loginUser.token+"&refresh_token="+this.$store.state.loginUser.refresh_token+"&tp="+this.comp_type);
            }else{
                this.src+=("?token="+this.$store.state.loginUser.token+"&refresh_token="+this.$store.state.loginUser.refresh_token+"&tp="+this.comp_type);
            }
        },
        mounted() {
            window.addEventListener("message",(event)=> {
                console.log('event',event);
                let result = JSON.parse(event.data);
                if (result.type == "to_login") {
                    logOut().then();
                }
                if (result.type=="refresh_token") {
                    // 更新用户token
                    this.$store.dispatch('updateLogedUser', {
                        token:result.token,
                        refresh_token:result.refresh_token
                    });
                }
            }, false);
        },
        methods: {
            onLoaded() {
                this.loading=false;
            }
        }
    }
</script>